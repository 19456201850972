html {
  --game-spacing: 32px;
  --header-height: 4rem;
  --color-success: #17824d;
  --color-warning: #998000;
  --color-error: #c32222;
  --color-gray-100: #1a1a1a;
  --color-gray-300: #404040;
  --color-gray-500: gray;
  --color-gray-700: #bfbfbf;
  --color-gray-900: #e6e6e6;
  overflow-y: scroll;
}

body {
  font-family: sans-serif;
}

@media (max-height: 600px) {
  body {
    --game-spacing: 8px;
  }
}

.wrapper {
  flex-direction: column;
  min-height: 100%;
  display: flex;
}

header {
  height: var(--header-height);
  border-bottom: 1px solid var(--color-gray-700);
  color: var(--color-gray-300);
  display: flex;
}

header .side {
  width: var(--header-height);
  place-content: center;
  display: grid;
}

h1 {
  font-size: 2rem;
  line-height: var(--header-height);
  text-align: left;
  flex: 1;
}

@media (max-width: 25rem) {
  h1 {
    font-size: 1.25rem;
  }
}

.game-wrapper {
  gap: var(--game-spacing);
  padding-top: 16px;
  padding-bottom: var(--game-spacing);
  padding-left: var(--game-spacing);
  padding-right: var(--game-spacing);
  flex-direction: column;
  flex: 1;
  min-width: max(300px, 39vh, 100%);
  max-width: min(413px, 80vh, 100%);
  margin: 0 auto;
  display: flex;
}

.banner {
  text-align: center;
  will-change: transform;
  border-radius: 4px 4px 0 0;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 32px;
  animation: .75s cubic-bezier(0, .72, .24, 1.02) slideUp;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.happy.banner {
  background: var(--color-success);
  color: #fff;
}

.sad.banner {
  background: var(--color-error);
  color: #fff;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

/*# sourceMappingURL=index.b85e0677.css.map */
